import React from "react"
import { Container, Grid } from '@material-ui/core'
import styled from 'styled-components'

export default function Food(props) {

    const Food = styled.h4`
        color:#000;
        font-size:1.8rem;
        text-transform:capitalize;
        @media (max-width:800px) {
            font-size:2.5rem;
        }
    `

    const Price = styled.span`
        font-family: 'lora', sans-serif;
        color:var(--red);
        font-weight:bold;
        padding-left:2rem;
    `

    const Notes = styled.p`
        color:var(--red);
        margin:0;
        text-transform:lowercase;
    `

    const Description = styled.p`
        margin-top:0;
    `    

    const FoodPorn = styled.div`
        margin-right:2rem;
        @media (max-width:1024px) {
            margin:0;
        }
    `

    return (
        <>
            <Grid item className="food">
                <div className="food-wrapper flex justify-center h-full">
                    {props.img ?
                    <FoodPorn>
                        {props.img}
                    </FoodPorn>
                    : null }
                    <span className="food-text">
                        <Food>{props.food} <Price>{props.price}</Price></Food>
                        {props.notes ? <Notes>{props.notes}</Notes> : null }
                        <Description>{props.description}</Description>
                    </span>
                </div>
            </Grid>            
        </>
    )
}
