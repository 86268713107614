import React from "react"
import { Link, graphql } from "gatsby"
import { Container, Grid } from "@material-ui/core"
import styled from "styled-components"
import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import Intro from "../components/menu/intro"
import Food from "../components/menu/food"

import Logo from "../images/mcreilly-logo.svg"
import Hero from "../images/menu_hero.png"

export default function Menu({ data }) {

  const HeroImg = styled.div`
    background-image: url(${Hero});
    background-size: cover;
    background-position: center;
    text-align: center;
    background-color: #2b2b2b;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  `

  const LogoContainer = styled.div`
    background-color: #212121;
    padding: 20px;
    display: flex;
    justify-content: center;
  `

  return (
    <>
      <Layout>
        <SEO title="Menu" />
        <LogoContainer>
          <Link to="/">
            <img alt="McReilly's Logo" src={Logo} width="200px" />
          </Link>
        </LogoContainer>
        <HeroImg>
          <h2 className="white-text">Our Menu</h2>
        </HeroImg>
        {data.allStrapiCategories.edges.map(({ node }) => (
          <div key={node.order} className={node.background ? 'menu menu-parallax' : 'menu'} style={{backgroundImage: `url(${node.background ? node.background.publicURL : null})`}}>
            <Container className="menu-section">
              <Intro title={node.category} subtitle={node.subtitle} />
              <Grid container>
                {node.foods.map(food => (
                  <>
                    <Food
                      key={food.id}
                      img={
                        <LazyLoadImage
                          src={food.foodimg.publicURL}
                          effect="blur"
                          width={150}
                          height={150}
                          alt={food.food}
                        />
                      }
                      food={food.food}
                      description={food.description}
                      notes={food.notes ? food.notes : null}
                      price={food.price}
                    />
                  </>
                ))}
              </Grid>
            </Container>
          </div>
        ))}
      </Layout>
    </>
  )
}

export const query = graphql`
query Foods {
  allStrapiCategories {
    edges {
      node {
        order
        category
        subtitle
        background {
          publicURL
        }
        foods {
          id
          food
          description
          notes
          price
          foodimg {
            publicURL
          }
        }
      }
    }
  }
}
`
