import React from 'react'

export default function Intro(props) {
    return (
        <div className="menu-content text-center">
            <h6 className="red-text">{props.title}</h6>
            {props.subtitle ? <h3 className="mb-12">{props.subtitle}</h3> : null }
            {props.description ? <p><strong>{props.description}</strong></p> : null }
        </div>
    )
}